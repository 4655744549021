import React, { useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './HomePage.css';

//Components
import Header from '../../components/header/Header';
import MenuItems from '../../components/menuItems/MenuItems';
import AppItems from '../../components/appList/AppList';
import DashBoardItems from '../../components/dashBoardList/DashBoardList';

//Dialog
import { ErrorDialog } from '../../dialogs/errorDialog/ErrorDialog';
import { WarningDialog } from '../../dialogs/warningDialog/WarningDialog';

//Navigate
import { useNavigate } from 'react-router-dom';

//Location
import { useLocation } from 'react-router-dom';

//Server
import Server from '../../serverDetails/server.js';

export default function LaunchPage() {
  const [aAppList, setAppList] = useState([]);
  const [iSelectedItem, setSelectedItem] = useState(1);
  const [sError, setErrorMessage] = useState('');
  const [bError, openErrorDialog] = useState(false);
  const [bWarning, openWarningDialog] = useState(false);
  const [bDashboardWarning, setDashboardWarning] = useState(false);
  const [oDashBoard, setDashBoard] = useState({});
  const [bLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  function handleMenuItemChange(iNewValue) {
    if (iNewValue === 0) {
      if (Object.keys(oDashBoard).length > 0) {
        setSelectedItem(iNewValue);
      } else {
        setDashboardWarning(true);
        setErrorMessage("You don't have access to the dashboard");
      }
    } else {
      setSelectedItem(iNewValue);
      localStorage.removeItem('Tab');
    }
  }
  if (iSelectedItem == 0 && !localStorage.getItem('Tab')) {
    localStorage.setItem('Tab', 'Tab-D');
  } else if (localStorage.getItem('Tab') && iSelectedItem === 1) {
    setSelectedItem(0);
    localStorage.removeItem('Tab');
  }

  /**
   * Execute while rendering StudioPage component
   */
  useEffect(() => {
    if (localStorage.getItem('CID') && localStorage.getItem('USERID')) {
      setLoading(true);
      Server.get('/launchtest/apps', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(function (response) {
          setAppList(response.data.data.Applications);
          setDashBoard(response.data.data.Dashboard);
          localStorage.setItem('D_APPID', response.data.data.Dashboard.APP_ID);
          if (response.data.length === 0) {
            openWarningDialog(true);
          }
          setLoading(false);
        })
        .catch(function (oError) {
          openErrorDialog(true);
          setErrorMessage(oError.message);
          setLoading(false);
          navigate('/');
        });
    } else {
      navigate('/');
    }
  }, []);
  return (
    <div className="HomePage-Wrapper">
      <Header />
      <MenuItems
        selectedItem={iSelectedItem}
        onChangeItem={handleMenuItemChange}
      />
      {bLoading ? (
        <div className="HomePage-busyindicator">
          <div className="overlay">
            <ScaleLoader
              color={'#033146'}
              loading={bLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
              height={25}
              width={6}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <ErrorDialog open={bError} message={sError} close={openErrorDialog} />
      <WarningDialog
        open={bWarning}
        message={
          localStorage.getItem('CUST_ID') + localStorage.getItem('USER_ID')
        }
        close={openWarningDialog}
      />
      <WarningDialog
        open={bDashboardWarning}
        close={setDashboardWarning}
        message={sError}
      />
      {iSelectedItem === 1 && (
        <AppItems
          appList={aAppList}
          bLoading={bLoading}
          userID={localStorage.getItem('USERID')}
        />
      )}
      {iSelectedItem === 0 && (
        <DashBoardItems
          dashBoard={oDashBoard}
          USERID={localStorage.getItem('USERID')}
        />
      )}
    </div>
  );
}
