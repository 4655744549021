import React, { useState } from 'react';
import Server from '../../serverDetails/server.js';
import { useParams } from 'react-router-dom';
import Logo from './images/logo.png';
import { useNavigate } from 'react-router-dom';
import '../loginPage/LoginPage.css';

export default function ForgotPassword() {
  let navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { CID,USERID } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password === confirmPassword){
        try {
            await Server.post(`/launchpad/signup`, {
                CID : CID,
                USERID,
                PASSWORD: password,
                updatePassword: true
            })
            .then((res) => {
                if (res.data.Message === 'Password Updated Successfully') {
                  navigate('/')
                } else {
                  alert("Error Occured")
                }
              })
        } catch (error) {
            alert("Error Occured")
        }
    }else{
        alert("Password and Confirm Password are not Same")
    }
  };
  return (
    <div className="login">
      <div class="login-container">
        <img src={Logo} alt="CloudZudio Logo" class="login-logo" />
        <div class="login-form">
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div class="form-group">
              <label for="ConfirmPassword">Confirm Password</label>
              <input
                type="password"
                id="ConfirmPassword"
                name="ConfirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div class="form-group">
              <input type="submit" value="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
