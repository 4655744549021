import React, { useState, useEffect, useRef } from 'react';
import './css/Header.css';
import AppLogo from './images/logo.png';
import ProfileLogo from './images/profile.png';
import { useNavigate } from 'react-router-dom';

function Header() {
  let navigate = useNavigate();
  const [showLogout, setShowLogout] = useState(false);
  const popupRef = useRef(null);

  function handleLogOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('CID');
    localStorage.removeItem('USERID');
    localStorage.removeItem('ADMIN');
    localStorage.removeItem('injected');
    navigate('/');
  }

  function toggleLogout() {
    setShowLogout(!showLogout);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target) && showLogout) {
        setShowLogout(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  return (
    <div className="LaunchPage-Header_headerContainer">
      <div className="LaunchPage-Header_logo">
        <a
          href={`http://${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ''
          }/HomePage`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AppLogo} alt="AppLaunch" className="logo" />
        </a>
      </div>
      <div className="LaunchPage-Header_profile">
        <div className="LaunchPage-Header_logout">
          <h4 id="LaunchPageLogout" onClick={handleLogOut}>
            Log out
          </h4>
        </div>
        <img
          src={ProfileLogo}
          alt="img"
          className="LaunchPage-Header_profileLogo"
          onClick={toggleLogout}
        />
        {showLogout && (
          <div className="popup-logout" ref={popupRef}>
            <p onClick={handleLogOut}>Log out</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;