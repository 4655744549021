import * as React from 'react';
import './MenuItems.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

function MenuItems(props) {
  function handleTabChange(oEvent, iNewValue) {
    props.onChangeItem(iNewValue);
  }
  

  return (
    <div className="MenuItems-Container">
      <div>
        <Box>
         <Tabs value={props.selectedItem} onChange={handleTabChange}>
            <Tab label="Dashboard" className="a1" />
            <Tab label="Applications" />
          </Tabs>
        </Box>
      </div>
    </div>
  );
}

export default MenuItems;
