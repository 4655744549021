import React from 'react';

//Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const WarningDialog = (props) => {
  const handleClose = () => {
    if (props.open) {
      props.close(false)
    }
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          No appilication assigned to your userID :{' '}
          <strong>{props.message}</strong>
        </Alert>
      </DialogContent>
    </Dialog>
  );
};
