import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppIcon from './images/AppIcon.png';
import IMGExpand from './images/Expand.png';
import IMGCollapse from './images/Collapse.png';
import $ from 'jquery';
import './AppList.css';

export default function AppItems(props) {
  
  let navigate = useNavigate();
  /**
   * Event triggered during click of Expand button
   * @param {object} oEvent | Click Event
   */
  function handleExpand(oEvent) {
    var id = oEvent.target.id.split('idExpandImg')[1];
    $(`#idExpand` + id).toggle();
    $(`#idCollapse` + id).toggle();
    $(`#idGrid` + id).slideDown();
  }

  /**
   * Event triggered during click of Expand button
   * @param {object} oEvent | Click Event
   */
  function handleCollapse(oEvent) {
    var id = oEvent.target.id.split('idCollapseImg')[1];
    $(`#idCollapse` + id).toggle();
    $(`#idExpand` + id).toggle();
    $(`#idGrid` + id).slideUp();
  }

  /**
   * Event handler to open appilication
   * @param {string} sAPPID | Appilication ID
   */
  function handleOnClickApp(sAPPID, sUserID, aPages) {
    // var sPageID = aPages[0];
    navigate(`/PreviewPage/${sAPPID}/page1`, {
      state: {
        APP_ID: sAPPID,
        USERID: sUserID,
        OPERATION: 'Launchpad'
      }
    });
  }

  return (
    <div className="AppList-AppContainer">
      {props.appList.length > 0 &&
        props.appList
          .sort((a, b) => a.GROUP_ORDER - b.GROUP_ORDER)
          .map((GroupItem, index) => (
            <div className="AppList-GroupContainer">
              <div className="AppList-TitleContainer">
                <div className="AppList-GroupTitle">{GroupItem.GROUP_DESC}</div>
                <div id={`idExpand` + index} className="AppList-ExpandButton">
                  <img
                    id={`idExpandImg` + index}
                    src={IMGExpand}
                    height="20px"
                    width="20px"
                    onClick={handleExpand}
                  />
                </div>
                <div
                  id={`idCollapse` + index}
                  className="AppList-CollapseButton"
                >
                  <img
                    id={`idCollapseImg` + index}
                    className="collapse"
                    src={IMGCollapse}
                    height="20px"
                    width="20px"
                    onClick={handleCollapse}
                  />
                </div>
              </div>
              <div id={`idGrid${index}`} className="AppList-GridContainer">
                {GroupItem.AppDetails.slice()
                  .sort((a, b) => a.ORDER - b.ORDER)
                  .map((AppItem, idx) => (
                    <a
                      href={`http://${window.location.hostname}${
                        window.location.port ? `:${window.location.port}` : ''
                      }/PreviewPage/${AppItem.APP_ID}/page1`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        if (e.button === 0) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div
                        key={`idGridItem${idx}`}
                        className="AppList-GridItem"
                        onClick={() => {
                          handleOnClickApp(
                            AppItem.APP_ID,
                            props.userID,
                            AppItem.pages
                          );
                        }}
                      >
                        <div className="AppList-AppTitle">
                          {AppItem.APP_DESC}
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          ))}
    </div>
  );
}
